import {ru} from './ru';
import {en} from './en';
import {kr} from './kr'; // корея
import {lv} from './lv'; // латвия
import {lt} from './lt'; // литва
import {de} from './de'; // германия
import {pl} from './pl'; // польша
import {tp} from './tp'; // португалия
import {tr} from './tr'; // турция
import {ua} from './ua'; // украина
import {fi} from './fi'; // финлядния
import {fr} from './fr'; // франция
import {ee} from './ee'; // эстония
import {jp} from './jp'; // япония
import {bg} from './bg'; // болгария
import {gr} from './gr'; // греция
import {es} from './es'; // испания
import {it} from './it'; // италия
import {cn} from './cn'; // китай
import {bn} from './bn'; // Бангладеш

export const translates = {
    ru,
    en,
    kr,
    lv,
    lt,
    de,
    pl,
    tp,
    tr,
    ua,
    fi,
    fr,
    ee,
    jp,
    bg,
    gr,
    es,
    it,
    cn,
    bn,
};
