import Vue from "vue";
import Vuex, {Store} from "vuex";

import {state} from './state';

import {mutations} from './mutations';
import {actions} from './actions';
import {getters} from './getters';

Vue.use(Vuex);

export const invoiceStore = new Store({
    state,
    mutations,
    actions,
    getters,
});
invoiceStore.dispatch('fetchInvoice', {});
