import {i18n} from "../../../../i18n";

export const changeLanguage = (state, lang) => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);

    urlParams.set('lang', lang)

    window.history.replaceState( {}, import.meta.env.VITE_PRODUCT_NAME, `${window.location.origin}${window.location.pathname}?${urlParams.toString()}` );


    try {
        document.cookie = `lang=${lang}`;
    } catch (e) {
        console.log('changeLang.js', e);
    }

    i18n.locale = lang;
};
