export const jp = {
    'invoice': {
        'invoice': 'アカウント番号',
        'sum-invoice': '請求金額です。',
        'select-invoice': '決済通貨を選択する',
        'coin-network': '通貨とネットワーク',
        'rate': 'コースです。',
        'sum-payment': '支払額です。',
        'button': '支払い',
        'search': '探す',
    },
    'checkout': {
        'shop': 'ショップ',
        'payment': '支払方法',
        'back_to_the_store': 'ショップに戻る',
        'to_make_a_payment': '支払いは、 "{orderCurrency}"を "{orderAlias}"ネットワークに送信してください。を指定されたアドレスに送信します。',
        'payment_completed': '支払い完了',
        'the_payment_is_invalid': 'お支払いが有効でない',
        'payment_rejected': '支払い拒否',
        'payment_pending': '処理中の支払い',
        'payment_init': '支払い保留',
        'lifetime_has_expired': '支払い期限が切れている。指定された住所にコインを送らないでください。',
        'contact_the_store_owner': '支払い期限が切れている。指定された住所にコインを送らないでください。返金または支払い継続を希望する場合は、店員に連絡すること',
        'order': 'ご注文',
        'to_be_paid': '支払予定日',
        'received': '受け取った。',
        'description': '説明',
        'before_the_expiration_of_the_order': '支払期限',
        'network': 'ネットワーク',
        'payment_address': '支払先',
        'payment_address_not_found': '支払い先住所が見つかりません',
        'tag': 'タグ:',
        'contract_address': '契約住所',
        'send_to_this_address_only': 'このアドレスにのみ送信する',
        'make_sure_the_network_is_correct': 'ネットワークが正しいかどうか確認してください。',
        'network_is_correct': 'ネットワークが正しいかどうか確認してください。',
        'contract_address_matches': '契約アドレスが一致していることを確認する',
        'payment_page': '決済ページ',
        'the_address_was_copied': 'アドレスのコピーに成功',
        'the_tag_was_copied': 'タグがコピーされました',
        'invoice_was_successfully_paid': '令状が正常に支払われた',
        'the_invoice_was_paid': 'の金額でワラントが支払われました。',
        'important_info': '重要なお知らせ',
        'the_contract_address_does_not_exist_on_this_network': '契約アドレスがこのネットワークに存在しない',
        'error': 'エラー',
        'time_hours': '時間',
        'time_minute': '議事録',
        'time_second': '替わり',
        'sellers_address': '売主の住所が確認される',
        'stored_table': '以下は、受信したトランザクションの履歴になります。',
        'history_table': '着信取引履歴',
        'full_info': '詳細を見る',
        'table_date': '日付',
        'table_sum': '金額',
        'table_value': '通貨',
        'table_hash': 'tx_hash',
        'table_date_sum': '金額と日付',
        'payment_partially_paid': '一部有給',
        'paid_timer': '有給',
        'expired_timer': '支払い期限が切れている',
    },
    'new_checkout': {
        'details': {
            'title': '詳細',
            'order': '注文',
            'date': '日にち',
            'description': '説明',
        },
        'notification': 'このアドレスに <b>{orderNetwork}</b> ネットワークに <b>{orderCurrency}</b> トークンのみを送信してください。',
        'payment_address': 'お支払い住所',
        'verified_notification': "売り手の住所が検証されています",
        'paid_amount': "支払金額",
        'timer_notifications': {
            'remaining_time': '支払う時間の残り',
            'completed': '支払いは正常に完了しました',
            'expired': '支払い寿命は期限切れでした',
        },
        'statuses': {
            'not_paid': '支払われていません',
            'overpaid': '過払い',
            'partially_paid': '部分的に支払われます',
            'fully_paid': '完全支給',
        },
        'history': {
            'title': '支払い履歴',
            'columns': {
                'date': '日にち',
                'amount': '額',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': '指定されたアドレスに送信されるすべてのトランザクションは、このウィンドウで利用可能になります。',
            'ok': 'OK'
        },
        'orphan': {
            'title': '孤立したトランザクション',
            'description': 'この注文の取引の 1 つに間違ったネットワークまたは通貨が含まれています。',
            'contact': 'ID を使用してテクニカル サポートにお問い合わせください',
        },
        'time-is-over': '時間切れ',
        'payment-successful': '支払いが完了しました.',
        'partial-desc': '金額の一部は支払われています。支払いが完了したら、残りの金額を支払う必要があります。',
        'cancel': 'キャンセル',
        'back_to_shop': '買い物に戻ります'
    },
    'new_invoice': {
        'details': {
            title: '詳細',
            invoice: '請求書',
            date: '日にち',
            description: '説明'
        },
        'amount': '額',
        'btn_label': '続く',
        'btn_label_empty': '通貨を選択します',
        'cancel': 'キャンセル',
        'search': '検索...',
        'back_to_shop': '買い物に戻ります',
        'choose-network': 'ネットワークの選択'
    },
    'languages': {
        'select': '言語を選択する'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'ページが見つかりません',
            'page_not_found_text': 'あなたが探しているページを見つけることができないようです',
        }
    }
};
