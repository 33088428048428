import Vue from 'vue';

// import Page from '/public/pages/invoice/invoice.vue';
import Page from '@/entries/invoice/page/invoice.vue';

import {invoiceStore} from "/public/pages/invoice/store";
import {i18n} from '/public/i18n';

new Vue({
    el: "#app",
    store: invoiceStore,
    render: h => h(Page),
    i18n,
});
