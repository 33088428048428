import BigNumber from "bignumber.js";

export const getters = {
    received(state) {
        return new BigNumber(state.invoice.receivedAmount).decimalPlaces(8).toFixed();
    },

    amount(state) {
        return new BigNumber(state.invoice.amount).decimalPlaces(8).toFixed();
    },
};