export const state = {
    fetching: false,
    fetched: false,
    invoice: {
        amount: '',
        currencies: '',
        currencyName: '',
        id: '',
        itemDescription: '',
        itemId: '',
        returnURL: '',
        orderId: '0',
        orderLink: '',
        receivedAmount: '',
        status: '',
        organization: {}
    },
    currency: '',
    themeStatus: '',
    lang: localStorage.getItem('lang') || 'en',
    openedModal: null,
    openedModalPayload: {}
};
