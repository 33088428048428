import Vue from "vue";
import VueI18n from "vue-i18n";

import { translates } from "./translates";
import {getCookie} from "../common/getCookieLang";

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: getCookie('lang') || 'en',
    messages: translates,
});