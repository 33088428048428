export const fi = {
    'invoice': {
        'invoice': 'Tilinumero:',
        'sum-invoice': 'Tilin summa:',
        'select-invoice': 'Valitse maksuvaluutta',
        'coin-network': 'Valuutta ja verkko:',
        'rate': 'Hyvin:',
        'sum-payment': 'Maksun määrä:',
        'button': 'Maksu',
        'search': 'Hae',
    },
    'checkout': {
        'shop': 'Pisteet',
        'payment': 'Maksu',
        'back_to_the_store': 'Takaisin kauppaan',
        'to_make_a_payment': 'Lähetä «{orderCurrency}» verkossa «{orderAlias}» suorittaaksesi maksun määritettyyn osoitteeseen',
        'payment_completed': 'Maksu suoritettu',
        'the_payment_is_invalid': 'Maksu ei kelpaa',
        'payment_rejected': 'Maksu hylätty',
        'payment_pending': 'Maksu käsittelyssä',
        'payment_init': 'Maksu vireillä',
        'lifetime_has_expired': 'Maksun käyttöikä on umpeutunut. Älä lähetä kolikoita määritettyyn osoitteeseen.',
        'contact_the_store_owner': 'Maksun käyttöikä on umpeutunut. Älä lähetä kolikoita määritettyyn osoitteeseen. Ota yhteyttä kaupan omistajaan kolikoiden palauttamiseksi tai maksun jatkamiseksi',
        'order': 'Tilaus',
        'to_be_paid': 'Maksaa',
        'received': 'Sai:',
        'description': 'Kuvaus:',
        'before_the_expiration_of_the_order': 'Maksun voimassaoloaika:',
        'network': 'Netto:',
        'payment_address': 'Maksuosoite:',
        'payment_address_not_found': 'Maksuosoitetta ei löydy',
        'tag': 'Tagi:',
        'contract_address': 'Sopimusosoite:',
        'send_to_this_address_only': 'Lähetä vain tähän osoitteeseen',
        'make_sure_the_network_is_correct': 'Varmista, että verkko on oikea:',
        'network_is_correct': 'Kontrollige, kas võrk on õige:',
        'contract_address_matches': 'Varmista, että sopimusosoite täsmää',
        'payment_page': 'Maksusivu',
        'the_address_was_copied': 'Osoitteen kopioiminen onnistui',
        'the_tag_was_copied': 'Tunniste on kopioitu',
        'invoice_was_successfully_paid': 'Tilaus maksettiin onnistuneesti',
        'the_invoice_was_paid': 'Tilaus maksettiin',
        'important_info': 'Tärkeää tietoa',
        'the_contract_address_does_not_exist_on_this_network': 'Sopimusosoitetta ei ole tässä verkossa',
        'error': 'Virhe',
        'time_hours': 'tuntia',
        'time_minute': 'pöytäkirja',
        'time_second': 'sekuntia',
        'sellers_address': 'Myyjän osoite on vahvistettu',
        'stored_table': 'Tässä on saapuvien tapahtumien historia',
        'history_table': 'Saapuvien liiketoimien historia',
        'full_info': 'Näytä täydelliset tiedot',
        'table_date': 'päivämäärä',
        'table_sum': 'Summa',
        'table_value': 'Valuuta',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Määrä ja päivämäärä',
        'payment_partially_paid': 'Osittain maksettu',
        'paid_timer': 'Maksettu',
        'expired_timer': 'Maksu on vanhentunut',
    },
    'new_checkout': {
        'details': {
            'title': 'Yksityiskohdat',
            'order': 'Tilaus',
            'date': 'Päivämäärä',
            'description': 'Kuvaus',
        },
        'notification': 'Lähetä vain <b>{orderCurrency}</b>-rahakkeet <b>{orderNetwork}</b>-verkkoon tähän osoitteeseen.',
        'payment_address': 'Maksuosoite',
        'verified_notification': "Myyjän osoite on vahvistettu",
        'paid_amount': "Maksettu summa",
        'timer_notifications': {
            'remaining_time': 'Jäljellä oleva aika maksaa:',
            'completed': 'Maksu on suoritettu onnistuneesti',
            'expired': 'Maksuikä päättyi',
        },
        'statuses': {
            'not_paid': 'Ei maksettu',
            'overpaid': 'Ylimääräinen',
            'partially_paid': 'Osittain maksettu',
            'fully_paid': 'Kokonaan maksettu',
        },
        'history': {
            'title': 'Maksuhistoria',
            'columns': {
                'date': 'Päivämäärä',
                'amount': 'Määrä',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Kaikki määritettyyn osoitteeseen lähetetyt tapahtumat ovat saatavilla tässä ikkunassa.',
            'ok': 'OK'
        },
        'orphan': {
            'title': 'Orpo kauppa',
            'description': 'Yksi tämän tilauksen tapahtumista sisältää väärän verkon tai valuutan.',
            'contact': 'Ota yhteyttä tekniseen tukeen tunnuksella',
        },
        'time-is-over': 'Aika on lopussa',
        'payment-successful': 'Maksu onnistui',
        'partial-desc': 'Summa on osittain maksettu. loppusumma on maksettava onnistuneesta maksusta.',
        'cancel': 'Peruuttaa',
        'back_to_shop': 'Takaisin kauppaan'
    },
    'new_invoice': {
        'details': {
            title: 'Yksityiskohdat',
            invoice: 'Lasku',
            date: 'Päivämäärä',
            description: 'Kuvaus'
        },
        'amount': 'Määrä',
        'btn_label': 'Jatkaa',
        'btn_label_empty': 'Valitse valuutta',
        'cancel': 'Peruuttaa',
        'search': 'Hae...',
        'back_to_shop': 'Takaisin kauppaan',
        'choose-network': 'Valitse verkko'
    },
    'languages': {
        'select': 'Valitse kieli'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Sivua ei löydetty',
            'page_not_found_text': 'Emme näytä löytävän etsimäsi sivua',
        }
    }
};
