export const gr = {
    'invoice': {
        'invoice': 'Αριθμός λογαριασμού:',
        'sum-invoice': 'Ποσό λογαριασμού:',
        'select-invoice': 'Επιλέξτε νόμισμα πληρωμής',
        'coin-network': 'Νόμισμα και δίκτυο:',
        'rate': 'Καλά:',
        'sum-payment': 'Ποσό ΠΛΗΡΩΜΗΣ:',
        'button': 'Πληρωμή',
        'search': 'Αναζήτηση',
    },
    'checkout': {
        'shop': 'Σκορ',
        'payment': 'Πληρωμή',
        'back_to_the_store': 'Επιστροφή στο κατάστημα',
        'to_make_a_payment': 'Στείλτε το «{orderCurrency}» στο δίκτυο «{orderAlias}» για να πραγματοποιήσετε μια πληρωμή στην καθορισμένη διεύθυνση',
        'payment_completed': 'Η πληρωμή ολοκληρώθηκε',
        'the_payment_is_invalid': 'Η πληρωμή δεν είναι έγκυρη',
        'payment_rejected': 'Η πληρωμή απορρίφθηκε',
        'payment_pending': 'Πληρωμή κατά την επεξεργασία',
        'payment_init': 'Πληρωμή σε εκκρεμότητα',
        'lifetime_has_expired': 'Η διάρκεια της πληρωμής έχει λήξει. Μην στέλνετε νομίσματα στην καθορισμένη διεύθυνση.',
        'contact_the_store_owner': 'Η διάρκεια της πληρωμής έχει λήξει. Μην στέλνετε νομίσματα στην καθορισμένη διεύθυνση. Επικοινωνήστε με τον ιδιοκτήτη του καταστήματος για να επιστρέψετε νομίσματα ή να συνεχίσετε την πληρωμή',
        'order': 'Σειρά',
        'to_be_paid': 'Να πληρώσω',
        'received': 'Λήφθηκε:',
        'description': 'Περιγραφή:',
        'before_the_expiration_of_the_order': 'Περίοδος ισχύος πληρωμής:',
        'network': 'Καθαρά:',
        'payment_address': 'Διεύθυνση πληρωμής:',
        'payment_address_not_found': 'Η διεύθυνση πληρωμής δεν βρέθηκε',
        'tag': 'Ετικέτα:',
        'contract_address': 'Διεύθυνση σύμβασης:',
        'send_to_this_address_only': 'Αποστολή μόνο σε αυτή τη διεύθυνση',
        'make_sure_the_network_is_correct': 'Βεβαιωθείτε ότι το δίκτυο είναι σωστό:',
        'network_is_correct': 'Βεβαιωθείτε ότι το δίκτυο είναι σωστό:',
        'contract_address_matches': 'Βεβαιωθείτε ότι η διεύθυνση του συμβολαίου ταιριάζει',
        'payment_page': 'Σελίδα πληρωμής',
        'the_address_was_copied': 'Η διεύθυνση αντιγράφηκε με επιτυχία',
        'the_tag_was_copied': 'Η ετικέτα έχει αντιγραφεί',
        'invoice_was_successfully_paid': 'Η παραγγελία πληρώθηκε με επιτυχία',
        'the_invoice_was_paid': 'Η παραγγελία πληρώθηκε',
        'important_info': 'Σημαντικές πληροφορίες',
        'the_contract_address_does_not_exist_on_this_network': 'Η διεύθυνση σύμβασης δεν υπάρχει σε αυτό το δίκτυο',
        'error': 'Λάθος',
        'time_hours': 'ώρες',
        'time_minute': 'λεπτά',
        'time_second': 'δευτερόλεπτα',
        'sellers_address': 'Η διεύθυνση του πωλητή έχει επαληθευτεί',
        'stored_table': 'Εδώ θα είναι το ιστορικό των εισερχόμενων συναλλαγών',
        'history_table': 'Ιστορικό εισερχόμενων συναλλαγών',
        'full_info': 'Εμφάνιση πλήρεις λεπτομέρειες',
        'table_date': 'η ημερομηνία',
        'table_sum': 'Αθροισμα',
        'table_value': 'Νόμισμα',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Ποσό και ημερομηνία',
        'payment_partially_paid': 'Μερικώς πληρωμένη',
        'paid_timer': 'Πληρωμή',
        'expired_timer': 'Η πληρωμή έχει λήξει',
    },
    'new_checkout': {
        'details': {
            'title': 'Λεπτομέριες',
            'order': 'Σειρά',
            'date': 'Ημερομηνία',
            'description': 'Περιγραφή',
        },
        'notification': 'Παρακαλείσθε να στείλετε μόνο τα σημάδια <b>{orderCurrency}</b> στο δίκτυο <b>{orderNetwork}</b> σε αυτήν τη διεύθυνση.',
        'payment_address': 'διεύθυνση πληρωμής',
        'verified_notification': "Η διεύθυνση του πωλητή έχει επαληθευτεί",
        'paid_amount': "Πληρωμένο ποσό",
        'timer_notifications': {
            'remaining_time': 'Απομένει χρόνο για πληρωμή:',
            'completed': 'Η πληρωμή έχει ολοκληρωθεί με επιτυχία',
            'expired': 'Η διάρκεια ζωής της πληρωμής έληξε',
        },
        'statuses': {
            'not_paid': 'Απληρωτος',
            'overpaid': 'Υπερπληρωμένος',
            'partially_paid': 'Μερικώς πληρωμένος',
            'fully_paid': 'Πλήρως πληρωμένος',
        },
        'history': {
            'title': 'Ιστορικό πληρωμών',
            'columns': {
                'date': 'Ημερομηνία',
                'amount': 'Ποσό',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Όλες οι συναλλαγές σας που αποστέλλονται στην καθορισμένη διεύθυνση θα είναι διαθέσιμες σε αυτό το παράθυρο.',
            'ok': 'Εντάξει'
        },
        'orphan': {
            'title': 'Ορφανή συναλλαγή',
            'description': 'Μία από τις συναλλαγές σε αυτήν την παραγγελία περιέχει λάθος δίκτυο ή νόμισμα.',
            'contact': 'Επικοινωνήστε με την τεχνική υποστήριξη με αναγνωριστικό',
        },
        'time-is-over': 'Ο χρόνος τελείωσε',
        'payment-successful': 'Η πληρωμή ολοκληρώθηκε με επιτυχία',
        'partial-desc': 'Το ποσό έχει καταβληθεί εν μέρει. το υπόλοιπο ποσό πρέπει να καταβληθεί για επιτυχή πληρωμή.',
        'cancel': 'Ματαίωση',
        'back_to_shop': 'Επιστροφή στο κατάστημα'
    },
    'new_invoice': {
        'details': {
            title: 'Λεπτομέριες',
            invoice: 'Τιμολόγιο',
            date: 'Ημερομηνία',
            description: 'Περιγραφή'
        },
        'amount': 'Ποσό',
        'btn_label': 'Να συνεχίσει',
        'btn_label_empty': 'Επιλέξτε νόμισμα',
        'cancel': 'Ματαίωση',
        'search': 'Αναζήτηση...',
        'back_to_shop': 'Επιστροφή στο κατάστημα',
        'choose-network': 'Επιλέξτε δίκτυο'
    },
    'languages': {
        'select': 'Επιλέξτε γλώσσα'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Η σελίδα δεν βρέθηκε',
            'page_not_found_text': 'Δεν μπορούμε να βρούμε τη σελίδα που ψάχνετε',
        }
    }
};
